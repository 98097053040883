import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/Common/Layout";
import CompanyLogo from "../../components/Website/TrustUs/CompanyLogo";
import ProjectsNavigation from "../../components/Website/ProjectsNavigation";
import ServicePellet from "../../components/Website/ServicePellet";

const Sublime = ({ data }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const imagesData = data.allFile.edges.map(
    (edge) => edge.node.childImageSharp.fluid
  );

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage="en"
      title="Brocode Studio || Sublime Comporta"
      description="One of the best luxury hotels in Portugal and awarded
      internationally with excelence awards."
    >
      <div className="project-page">
        <div className="brocode-content">
          <Row className="bk-about-area">
            <Col md={6} xs={12}>
              <Fade bottom duration={2000}>
                <div className="content">
                  <div className="title wow move-up mb--10">
                    <h2>Sublime Comporta</h2>
                  </div>
                  <ServicePellet service="Branding" />
                  <p className="wow move-up mb--20">
                    One of the best luxury hotels in Portugal and awarded
                    internationally with excelence awards.
                  </p>
                  <p className="wow move-up mb--20">
                    A unique luxury hotel with a unique design.
                  </p>
                  <p className="wow move-up mb-0">Distinguished by:</p>
                  <Row>
                    <Col xs={12}>
                      <img
                        src={imagesData[17].src}
                        style={{ height: "20px", marginRight: "20px" }}
                      />
                      <img
                        src={imagesData[18].src}
                        style={{ height: "20px" }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs={12}>
                      <img
                        src={imagesData[19].src}
                        style={{ height: "20px", marginRight: "20px" }}
                      />
                      <img
                        src={imagesData[20].src}
                        style={{ height: "20px" }}
                      />
                    </Col>
                  </Row>
                </div>
              </Fade>
            </Col>
            <Col md={6} xs={12}>
              <Img fluid={imagesData[16]} />
            </Col>
          </Row>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[0]} />
                <p className="my-4">Sublime business cards</p>
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[8]} />
                <p className="my-4">Sublime wine labels</p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[9]} />
                <p className="my-4">Restaurants brochures menu</p>
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[10]} />
                <p className="my-4">Restaurants brochures menu</p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[11]} />
                <p className="my-4">Restaurants brochures menu</p>
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[12]} />
                <p className="my-4">Sublime uniforms</p>
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[13]} />
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[14]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[15]} />
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[1]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[2]} />
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[3]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[4]} />
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[5]} />
              </Col>
            </Row>
          </Fade>
          <Fade bottom duration={2000}>
            {" "}
            <Row className="my-4">
              <Col md={6} xs={12}>
                <Img fluid={imagesData[6]} />
              </Col>
              <Col md={6} xs={12}>
                <Img fluid={imagesData[7]} />
              </Col>
            </Row>
          </Fade>

          <Fade bottom duration={2000}>
            <Row className="justify-content-center">
              <CompanyLogo name="sublime" />
            </Row>
          </Fade>

          <ProjectsNavigation
            nextProject={{ name: "Bestseller App", link: "/work/bestseller" }}
            prevProject={{ name: "Skippit App", link: "/work/skippit" }}
            selectedLanguage="en"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Sublime;

export const SublimeQuery = graphql`
  query SublimePhotos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)|(svg)/" }
        dir: { regex: "/static/img/projects/sublime/" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
